﻿md-datepicker {
  &.no-icon {
    button {
      display: none;
    }

    .md-datepicker-input-container {
      margin-left: 0;
    }
  }
}