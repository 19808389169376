﻿#calendar {
  &.fc {
    .fc-widget-header {
      border: none;
      color: rgba(0, 0, 0, 0.54);

      .fc-day-header {
        text-align: left;
        border: none;
        font-weight: 500;
        padding: 8px;
      }
    }

    .fc-widget-content {
      color: rgba(0, 0, 0, 0.54);

      .fc-day-grid-container {
        .fc-day-grid {
          .fc-day-number {
            text-align: left;
            padding: 8px 8px 0 8px;
          }
        }
      }

      .fc-time-grid-container {
        .fc-axis {
          font-weight: 500;
          border: none;
        }
      }
    }

    .fc-day-grid-event {
      margin: 4px 8px 0 8px;
      padding: 2px 4px;
      font-size: 13px;
      color: #FFFFFF;
    }

    .fc-time-grid-event {
      color: #FFFFFF;
    }

    .fc-month-view {
      padding: 24px;
    }

    .fc-agenda-view {
      .fc-widget-header {
        .fc-day-header {
          border: 1px solid #DDD;
          font-size: 17px;
        }
      }

      > table > tbody > tr > td.fc-widget-content {
        border: none;
      }

      .fc-minor .fc-widget-content {
        border-top: none;
      }

      .fc-axis {
        padding-left: 24px;
      }
    }
  }
}

md-checkbox.calendar {
    .md-icon{
        margin-left:2px;
    }

    &.md-checked .md-icon {
        color: black;
        background-color: inherit;
    }

    &[disabled] .md-icon {
        border-color: inherit;
    }



    &.holiday .md-icon {
        border-color: rgb(126,87,194);
    }

    &.holiday.md-checked .md-icon {
        background-color: rgb(126,87,194);
    }

    &[disabled].holiday .md-icon {
        border-color: rgba(126,87,194,.25);
    }
}