.sysmsg-feature {
    left:0;

    &:after {
        content: "";
        display: block;
        width: 4px;
        position: absolute;
        top: 0;
        left:0;
        bottom: 0;
        background: rgb(2,136,209);
    }
}

.sysmsg-bug {
    left:0;

    &:after {
        content: "";
        display: block;
        width: 4px;
        position: absolute;
        top: 0;
        left:0;
        bottom: 0;
        background: #F44336;
    }
}