﻿fieldset.standard {
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0,0,0, 0.3);
    border-radius: 3px;
}

md-input-container {
    .hint {
        margin-top: 2px;
        /* Copy styles from ng-messages */
        font-size: 12px;
        line-height: 14px;
        transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
        /* Set our own color */
        color: grey;

        &.ng-hide,
        &.ng-enter,
        &.ng-leave.ng-leave-active {
            bottom: 26px;
            opacity: 0;
        }

        &.ng-leave,
        &.ng-enter.ng-enter-active {
            bottom: 7px;
            opacity: 1;
        }
    }

    &.md-input-invalid .hint {
        display: none;
    }
}

textarea.no-style {
    background: transparent;
    border: 0;
    font-size: 14px;
    height: 100%;
    margin: 0;
    line-height: 18px;
    min-height: 18px;
    outline: none;
    padding: 0;
    position: absolute;
    resize: none;
    width: 100%;
    overflow-y: hidden;
}

.field-error {
    font-size: 12px;
    line-height: 14px;
    overflow: hidden;
    color: rgb(221,44,0);
}