﻿ce-badged-button {
  a {
    cursor: pointer;
    position: relative;

    i {
      color: #fff;
      background: #f44336;
      border-radius: 2px;
      font-size: 11px;
      font-style: normal;
      font-weight: bold;
      line-height: 15px;
      padding: 1px 5px;
      position: absolute;
      right: 2px;
      top: -7px;
    }
  }
}

.md-blueGrey-theme ce-badged-button i {
  background-color: #D84315;
}

.md-deepOrange-theme ce-badged-button i {
  background-color: #03A9F4;
}

.md-green-theme ce-badged-button i {
  background-color: #9c27b0;
}

.md-default-theme,
.md-deepPurple-theme {
  ce-badged-button i {
    background-color: rgb(255,64,129);
  }
}