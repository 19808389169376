﻿aims-progress-bar {
  background-color: #f1f1f1;
  display: inline-block;
  margin: 2px 0;
  line-height: 24px;

  > div {
    background-color: rgb(2,136,209);
    border-radius: 2px;
    color: #fff;
    line-height: inherit;

    > div {
      background-color: rgb(2,136,209);
      padding: 2px 16px;
    }
  }
}