﻿table tr {
  &[ng-click]{
    cursor: pointer;
  }

  &.bb {
    td {
      border-bottom: solid 1px #ddd;
    }
  }
}
