﻿ce-stepper {
  .ce-stepper-group {
    color: rgba(70,83,101,.5);

    .ce-stepper-group-number {
      width: 24px;
      height: 24px;
      margin: 0 8px;
      border-radius: 24px;
      border: 1px solid rgba(70, 83, 101,.5);
      line-height: 24px;
      text-align: center;
      font-size: 12px;
    }

    .ce-stepper-group-title {
      font-size: 14px;
      line-height: 14px;
      text-transform: uppercase;
      font-weight: 500;
      padding: 0 5px;
    }

    &.active {
      font-weight: 500;
      color: rgb(70,83,101);

      .ce-stepper-group-number {
        border-color: rgb(70, 83, 101);
      }
    }
  }

  .ce-stepper-group-scope {
    margin-left: 20px;

    .ce-stepper-step:first-child {
      padding-top: 20px;
    }

    .ce-stepper-step:last-child {
      padding-bottom: 20px;
    }
  }

  .ce-stepper-step {
    color: rgba(70, 83, 101,.5);
    padding-top: 15px;
    border-left: 1px solid rgba(70, 83, 101, .5);

    .ce-stepper-step-title {
      margin-left: 20px;
      font-size: 14px;
      line-height: 14px;
      padding: 0 5px;
    }

    .ce-stepper-step-token-wrapper {
      position: relative;

      .ce-stepper-step-token {
        display: none;
        position: absolute;
        top: 2px;
        left: -6px;
        background-color: #69c4eb;
        width: 11px;
        height: 11px;
        border-radius: 11px;
      }
    }

    &.active, &.completed {
      color: rgb(70, 83, 101);
      font-weight: 500;
      border-color: rgb(70, 83, 101);
    }

    &.active {
      color: #69c4eb;

      .ce-stepper-step-token {
        display: inline-block;
      }
    }
  }

  .ce-stepper-terminator {
    width: 11px;
    height: 11px;
    border-radius: 11px;
    background-color: rgba(70, 83, 101, .5);
    margin-left: 15px;
  }
}