﻿#search {
    background: rgba(255, 255, 255, .15);
    border-radius: 4px;
    min-width: 100px;
    position: relative;
    padding: 5px;

    md-icon {
        color: rgb(255, 255, 255) !important;
    }

    &:hover {
        background: rgba(255, 255, 255, .25);
        outline: 2px solid transparent;
    }

    >md-icon {
        margin: 0 10px;
    }

    .name_div {
        min-width: 800px;
    }

    input {
        color: #fff;
        background: none;
        border: none;
        outline: none;
        width: 100%;

        &::-webkit-input-placeholder {
            color: rgba(255, 255, 255, .75);
        }

        &::-moz-placeholder {
            color: rgba(255, 255, 255, .75);
        }

        &:-ms-input-placeholder {
            color: rgba(255, 255, 255, .75);
        }

        &::placeholder {
            color: rgba(255, 255, 255, .75);
        }
    }

    md-autocomplete {
        background: none;
        height: 34px;

        md-autocomplete-wrap {
            height: 34px;

            &.md-whiteframe-1dp,
            &.md-whiteframe-z1 {
                box-shadow: none;
            }

            button {
                display: none;
            }

            input {
                background: none;
                border: none;
                color: #fff;
                outline: none;
                padding: 0;
                font-size: inherit;
                height: 34px;
                line-height: 34px;

                &::-webkit-input-placeholder {
                    color: rgba(255, 255, 255, .75);
                }

                &::-moz-placeholder {
                    color: rgba(255, 255, 255, .75);
                }

                &:-ms-input-placeholder {
                    color: rgba(255, 255, 255, .75);
                }

                &::placeholder {
                    color: rgba(255, 255, 255, .75);
                }
            }
        }
    }
}

#search2 {
    border-radius: 15px;
    min-width: 100px;
    position: relative;
    padding: 5px;
    margin-bottom: 12px;
    box-shadow: 0 .5px 1.5px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);

    &:hover {
        background: #FEFFFE;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25), 0 2px 2px rgba(0, 0, 0, 0.22);
    }

    >md-icon {
        margin: 0 10px;
    }

    .name_div {
        min-width: 800px;
    }

    input {
        background: none;
        border: none;
        outline: none;
        width: 100%;
    }

    md-autocomplete {
        background: none;
        height: 34px;

        md-autocomplete-wrap {
            height: 34px;

            &.md-whiteframe-1dp,
            &.md-whiteframe-z1 {
                box-shadow: none;
            }

            button {
                display: none;
            }

            input {
                background: none;
                border: none;
                outline: none;
                padding: 0;
                font-size: inherit;
                height: 34px;
                line-height: 34px;
            }
        }
    }
}

.search-autocomplete-template {
    li {
        border-bottom: 1px solid #ccc;
        height: auto;
        padding-left: 8px;
        padding-top: 8px;
        padding-bottom: 8px;
        white-space: normal;

        md-autocomplete-parent-scope {
            .left-panel {
                float: left;


            }

            .right-panel {
                float: left;
                margin-left: 8px;
                margin-top: -10px;

                span:first-child {
                    font-size: 16px;
                }

                span:nth-child(2) {
                    display: block;
                    font-size: 14px;
                    color: gray;
                    margin-top: -25px;
                    margin-bottom: -25px;

                    span {
                        display: inline;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}