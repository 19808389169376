﻿.md-button {
    min-width: 1%;

    &.selected {
        background: #eee;

        &:hover {
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .26);
        }
    }

    &.green {
        color: rgb(255, 255, 255);
        background-color: #388E3C;

        &:hover {
            background-color: #43A047 !important;
        }

        md-icon {
            color: rgb(255, 255, 255) !important;
        }
    }

    &.md-small {
        min-width: 1%;
        width: 20px;
        height: 20px;
        line-height: 20px;
        min-height: 20px;
        vertical-align: top;
        font-size: 10px;
        padding: 0 0;
        margin: 0;

        md-icon {
            width: 20px;
            line-height: 20px;
            height: 20px;
            min-width: 20px;
            min-height: 20px;
            font-size: 20px;
        }
    }
}

.md-primary.md-raised {
    color: rgb(255, 255, 255) !important;

    md-icon {
        color: rgb(255, 255, 255) !important;
    }
}