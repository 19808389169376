﻿$colors: ( ('red', #f44336), ('pink', #e91e63), ('purple', #9C27B0), ('deep-purple', #673AB7), ('indigo', #3F51B5), ('blue', #2196F3), ('light-blue', #03A9F4), ('cyan', #00BCD4), ('teal', #009688), ('green', #4CAF50), ('light-green', #8BC34A), ('lime', #CDDC39), ('yellow', #FFEB3B), ('amber', #FFC107), ('orange', #FF9800), ('deep-orange', #FF5722), ('brown', #795548), ('grey', #9E9E9E), ('blue-grey', #607D8B));
$sizes: (16, 32, 40, 48, 100, 300);

[md-colors] md-icon {
    color: inherit !important;
}

md-icon {
    @each $color in $colors {
        &.#{nth($color, 1)} {
            color: nth($color, 2);
        }
    }

    @each $color in $colors {
        &.bg-#{nth($color, 1)} {
            background: nth($color, 2) !important;
        }
    }

    @each $size in $sizes {
        &.md-#{$size} {
            font-size: #{$size}px;
            width: #{$size}px;
            height: #{$size}px;
            min-height: #{$size}px;
            min-width: #{$size}px;
        }
    }
}