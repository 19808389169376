﻿a {
  color: #0275d8;
  text-decoration: none;
  cursor: pointer;

  &:focus, &:hover {
    color: #014c8c;
    text-decoration: none;
    outline-width: 0;
  }
}

.ce-title {
  margin: 0;
  margin-bottom: 12px;
  display: inline-block;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  letter-spacing: 0.005em;
  color: #254356;
}

.ce-sub-title {
  font-size: 13px;
  font-weight: 400;
  letter-spacing: normal;
  font-weight: bolder;
  color: #82949e;
  text-transform: uppercase;
  padding-bottom: 16px;
}

.ce-label {
  color: #637986;
  text-transform: uppercase;
  font-size: 13px;
  white-space: nowrap;
}

.aims-floating-label {
  color: rgba(0,0,0,0.54);
  font-size: .8em;
  white-space: nowrap;
}