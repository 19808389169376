﻿md-list {
    &.md-list-navigation {
        md-list-item {

            .badge {
                color: #fff;
                background: #f44336;
                border-radius: 2px;
                font-size: 11px;
                font-style: normal;
                font-weight: bold;
                line-height: 15px;
                padding: 1px 6px;
                position: absolute;
                right: 8px;
            }

            md-icon {
                margin: 0 16px 0 0 !important;
            }

            &.active {
                md-icon {
                    color: rgb(2, 136, 209);
                }

                &:after {
                    content: "";
                    display: block;
                    width: 4px;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    background: rgb(2, 136, 209);
                }
            }
        }
    }
}

md-list-item {
    height: 48px;

    &.active,
    &.md-active {
        background-color: rgba(0, 0, 0, .05);

        h3,
        p {
            color: #212121;
            font-weight: bold !important;
        }
    }

    .md-secondary-container {
        .badge {
            background: #ff4081;
            color: #fff;
            font-weight: bold;
            padding: 5px;
            height: 11px;
            line-height: 11px;
            font-size: 11px;
            border-radius: 2px;
        }
    }
}