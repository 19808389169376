﻿flot {
    display: block;
    min-width: 200px;
    min-height: 200px;
    width: 100%;
    height: 100%;

    .legend {
        > table tr td {
            padding: 3px;
        }

        > table tr td:first-child {
            padding-left: 3px;
        }

        > table tr td:last-child {
            padding-right: 3px;
        }

        > table tr + tr td {
            padding-top: 0;
        }

        > div:first-child {
            border-color: rgba(0,0,0,.1) !important;
        }

        .legendColorBox > div,
        .legendColorBox > div > div {
            border-radius: 400px;
        }
    }
}

.flot-pie-label {
    padding: 3px 5px;
    font-size: 10px;
    text-align: center;
    color: #fff;
}

.flotTip {
    position: relative;
    padding: 5px;
    font-size: 12px !important;
    border-radius: 2px !important;
    border-color: transparent !important;
    background-color: rgba(0,0,0,.75) !important;
    color: #f1f1f1;
    z-index: 1101 !important;
}

md-grid-tile {
    aims-analysis-dashboard-chart,
    aims-analysis-query-chart {
        position: absolute;
        top: 16px;
        left: 0;
        right: 0;
        bottom: 48px;
        padding: 8px;

        > div {
            display: none;
            position: absolute;
            top: -16px;
            right: 0;
        }

        > aims-analysis-chart {
            > div > * {
                display: block;
            }

            table.md-table {
                margin-top: 25px;
            }
        }
    }
}

aims-analysis-chart,
aims-analysis-chart > div,
aims-analysis-bar-chart,
aims-analysis-column-chart,
aims-analysis-line-chart,
aims-analysis-pie-chart,
aims-analysis-stacked-bar-chart,
aims-analysis-stacked-column-chart {
    display: block;
    width: 100%;
    height: 100%;
}

aims-analysis-pivot-table-chart {
    table.md-table {
        .md-row {
            height: auto !important;
        }

        .md-cell, .md-column {
            padding: 5px !important;
            text-align: right;

            &:first-child {
                text-align: left;
            }
        }
    }
}
