﻿.m0 {
  margin: 0 !important;
}

.ml0 {
  margin-left: 0 !important;
}

.mr0 {
  margin-right: 0 !important;
}

.mt0 {
  margin-top: 0 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mh0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mv0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m {
  margin: $margin-base  !important;
}

.ml {
  margin-left: $margin-base  !important;
}

.mr {
  margin-right: $margin-base  !important;
}

.mt {
  margin-top: $margin-base  !important;
}

.mb {
  margin-bottom: $margin-base  !important;
}

.m-sm {
  margin: $margin-sm  !important;
}

.ml-sm {
  margin-left: $margin-sm  !important;
}

.mr-sm {
  margin-right: $margin-sm  !important;
}

.mt-sm {
  margin-top: $margin-sm  !important;
}

.mb-sm {
  margin-bottom: $margin-sm  !important;
}

.m-lg {
  margin: $margin-lg  !important;
}

.ml-lg {
  margin-left: $margin-lg  !important;
}

.mr-lg {
  margin-right: $margin-lg  !important;
}

.mt-lg {
  margin-top: $margin-lg  !important;
}

.mb-lg {
  margin-bottom: $margin-lg  !important;
}

.m-xl {
  margin: $margin-xl  !important;
}

.ml-xl {
  margin-left: $margin-xl  !important;
}

.mr-xl {
  margin-right: $margin-xl  !important;
}

.mt-xl {
  margin-top: $margin-xl  !important;
}

.mb-xl {
  margin-bottom: $margin-xl  !important;
}

.ml-64 {
  margin-left: 64px;
}

// Margin vertical / horizontal
.mv {
  @extend .mt;
  @extend .mb;
}

.mh {
  @extend .ml;
  @extend .mr;
}

.mv-lg {
  @extend .mt-lg;
  @extend .mb-lg;
}

.mv-xl {
  @extend .mt-xl;
  @extend .mb-xl;
}

.mh-lg {
  @extend .ml-lg;
  @extend .mr-lg;
}

.mv-sm {
  @extend .mt-sm;
  @extend .mb-sm;
}

.mh-sm {
  @extend .ml-sm;
  @extend .mr-sm;
}

// Paddings
// --------------------

.p {
  padding: $padding-base  !important;
}

.pl {
  padding-left: $padding-base  !important;
}

.pr {
  padding-right: $padding-base  !important;
}

.pt {
  padding-top: $padding-base  !important;
}

.pb {
  padding-bottom: $padding-base  !important;
}

.p-sm {
  padding: $padding-sm  !important;
}

.pl-sm {
  padding-left: $padding-sm  !important;
}

.pr-sm {
  padding-right: $padding-sm  !important;
}

.pt-sm {
  padding-top: $padding-sm  !important;
}

.pb-sm {
  padding-bottom: $padding-sm  !important;
}

.p-lg {
  padding: $padding-lg  !important;
}

.pl-lg {
  padding-left: $padding-lg  !important;
}

.pr-lg {
  padding-right: $padding-lg  !important;
}

.pt-lg {
  padding-top: $padding-lg  !important;
}

.pb-lg {
  padding-bottom: $padding-lg  !important;
}

.p-xl {
  padding: $padding-xl  !important;
}

.pl-xl {
  padding-left: $padding-xl  !important;
}

.pr-xl {
  padding-right: $padding-xl  !important;
}

.pt-xl {
  padding-top: $padding-xl  !important;
}

.pb-xl {
  padding-bottom: $padding-xl  !important;
}

// Padding vertical / horizontal
.pv {
  @extend .pt;
  @extend .pb;
}

.ph {
  @extend .pl;
  @extend .pr;
}

.pv-xl {
  @extend .pt-xl;
  @extend .pb-xl;
}

.ph-xl {
  @extend .pl-xl;
  @extend .pr-xl;
}

.pv-lg {
  @extend .pt-lg;
  @extend .pb-lg;
}

.ph-lg {
  @extend .pl-lg;
  @extend .pr-lg;
}

.pv-sm {
  @extend .pt-sm;
  @extend .pb-sm;
}

.ph-sm {
  @extend .pl-sm;
  @extend .pr-sm;
}

.p0 {
  padding: 0 !important;
}

.pl0 {
  padding-left: 0 !important;
}

.pr0 {
  padding-right: 0 !important;
}

.pt0 {
  padding-top: 0 !important;
}

.pv0 {
  @extend .pt0;
  @extend .pb0;
}

.ph0 {
  @extend .pl0;
  @extend .pr0;
}

.pb0 {
  padding-bottom: 0 !important;
}

.mr0 {
  margin-right: -12px !important;
}

.text-white {
  color: #fff !important;
}

.text-muted {
  color: rgba(0, 0, 0, 0.54);
}

.text-bold {
  font-weight: bold;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-justify {
  text-align: justify;
}

.text-nowrap {
  white-space: nowrap;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase,
.initialism {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-line-through {
  text-decoration: line-through;
}

.text-lg {
  font-size: 36px;
}

.text-16 {
  font-size: 16px;
}

.text-sm {
  font-size: 13px;
}

.bg-grey {
  @include make-bg(#fbfbfc, inherit);
}

.bg-white {
  @include make-bg(#fff, inherit);
}

.bg-grey-hover {
  &:hover {
    @include make-bg(#eee, inherit);
  }
}

.cursor-pointer {
  cursor: pointer;
}

.dot-separator {
  color: #333;
  line-height: 18px;
  font-weight: bold;
  padding: 0 5px;

  &:after {
    content: "\00b7";
  }
}

.b {
  border: solid 1px #ddd !important;
}

.b0 {
  border: none !important;
}

.bb0 {
  border-bottom: none !important;
}

.bl {
  border-left: solid 1px #ddd;
}

.bb {
  border-bottom: solid 1px #ddd;
}

.bt {
  border-top: solid 1px #ddd;
}

.bt0 {
  border-top: none !important;
}

.br {
  border-right: solid 1px #ddd;
}

.bb-dotted {
  border-bottom: dotted 1px #ddd;
}

.wd-xs {
  width: $wd-xs;
}

.wd-xxs {
  width: $wd-xxs;
}

.wd-sm {
  width: $wd-sm;
}

.wd-md {
  width: $wd-md;
}

.wd-lg {
  width: $wd-lg;
}

.wd-xl {
  width: $wd-xl;
}

.wd-xxl {
  width: $wd-xxl;
}

.wd-wide {
  width: $wd-wide;
}

.abs-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.label {
  color: rgba(0, 0, 0, .54);
  font-size: 12px;
  font-weight: 700;
  white-space: nowrap;
}

.no-outline {
  outline: none;
}

.normal-line-height {
  line-height: normal;
}

.tickler {
  white-space: normal;
  color: #637986;
  text-transform: uppercase;
  font-size: 13px;
  margin-right: $margin-base;
  align-self: center;
}

.tooltip-multiline {
  height: auto;
}