﻿a.ce-card {
    display: inline-block;
}

.ce-card {
    background: #fbfbfc;
    border: 1px solid #e0e4e7;
    border-radius: 2px;
    padding: 32px;
}

.aims-card-placeholder {
    background: #F8F8F8;
    border: 5px dashed rgba(0,0,0,.2);
    color: rgba(0,0,0,.2);
    font-size: x-large;
    font-weight: bold;
    margin: 20px 0px 20px 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.aims-card-placeholder .additional-text {
    font-size: large;
    font-style: italic;
}

.aims-card-placeholder:hover,
.aims-card-placeholder-hover {
    border: 5px dashed rgba(0,0,0,.35);
    color: rgba(0,0,0,.35);
    cursor: pointer;
}

a.aims-list-card {
    display:inline-block;
}

.aims-list-card {
    background: #fbfbfc;
    border: 1px solid #e0e4e7;
    border-radius: 2px;
    padding: 8px;
    margin:10px;
    font-size:.9em;
}

.aims-list-card p {
    margin:0px;
}

.aims-list-card:hover {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}