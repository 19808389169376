﻿.msg-bubble {
  flex: 1 1 auto;
  clear: both;

  > div {
    border-radius: 5px;
    padding: 8px;
    margin: 8px 12px;
    max-width: 80%;
    position: relative;
    transition: background-color 0.5s;

    .media {
      background: white; 
      padding: 5px; 
      border: solid 1px #ababab; 
      margin: 5px 0;
      img {
        width: 100%;
      }
    }
  }

  &.msg-inbound > div {
    background-color: #ccc;
    float: left;

    &::before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      left: -8px;
      top: 8px;
      border-top: 4px solid transparent;
      border-right: 8px solid #ccc;
      border-bottom: 4px solid transparent;
    }
  }

  &.msg-outbound > div {
    background-color: #2196f3;
    color: #fff;
    float: right;

    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      right: -8px;
      bottom: 8px;
      border-top: 4px solid transparent;
      border-left: 8px solid #2196f3;
      border-bottom: 4px solid transparent;
    }
  }
}
