﻿md-checkbox.md-checked .md-icon:after, md-checkbox.md-indeterminate .md-icon:after {
  display: inline-block;
}

.md-deepOrange-theme md-checkbox.md-checked {
  color: rgba(68,138,255,0.87) !important;

  .md-icon {
    background-color: rgba(68,138,255,0.87) !important;
  }
}

.md-green-theme md-checkbox.md-checked {
  color: #9c27b0 !important;

  .md-icon {
    background-color: #9c27b0 !important;
  }
}