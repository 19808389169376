﻿#source-search {
    background: rgba(255,255,255,.15);
    min-width: 144px;
    position: relative;

    &:hover {
        background: rgba(255,255,255,.25);
        outline: 2px solid transparent;
    }

    md-autocomplete {
        background: none;
        height: 30px;

        md-autocomplete-wrap {
            height: 30px;

            &.md-whiteframe-1dp,
            &.md-whiteframe-z1 {
                box-shadow: none;
            }

            button {
                display: none;
            }

            input {
                background: none;
                border-bottom: solid 1px;
                border-bottom-color: rgba(0,0,0,.12);
                outline: none;
                padding: 0;
                font-size: inherit;
                height: 30px;
                line-height: 30px;

                &::-webkit-input-placeholder {
                    color: rgba(0,0,0,.35);
                }

                &::-moz-placeholder {
                    color: rgba(0,0,0,.35);
                }

                &:-ms-input-placeholder {
                    color: rgba(0,0,0,.35);
                }

                &::placeholder {
                    color: rgba(0,0,0,.35);
                }
            }
        }
    }
}

.source-search-autocomplete-template {
    li {
        border-bottom: 1px solid #ccc;
        height: auto;
        padding-left: 8px;
        padding-top: 0;
        padding-bottom: 0;
        white-space: normal;

        md-autocomplete-parent-scope {
            .left-panel {
                float: left;
            }

            .right-panel {
                float: left;
                margin-left: 8px;
                margin-top: -10px;

                span:first-child {
                    font-size: 16px;
                }

                span:nth-child(2) {
                    display: block;
                    font-size: 14px;
                    color: gray;
                    margin-top: -25px;
                    margin-bottom: -25px;

                    span {
                        display: inline;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
