﻿.md-subheader {
  &.pv0 {
    .md-subheader-inner {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &.pr0 {
    .md-subheader-inner {
      padding-right: 0;
    }
  }
}