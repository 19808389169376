﻿.ngf-drop-box {
    background: #F8F8F8;
    border: 5px dashed rgba(0,0,0,.1);
    margin: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.ngf-drop-box:hover,
.ngf-drop-box-hover {
  border: 5px dashed rgba(0,0,0,.25);
}