﻿ce-photo {
  display: inline-block;

  &.md-avatar {
    img {
      -webkit-box-flex: 0;
      -webkit-flex: none;
      flex: none;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      box-sizing: content-box;
    }
  }
}

.photo-64 {
  img {
    max-height: 64px;
    max-width: 64px;
    flex: none;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }
}

.photo-72 {
  img {
    max-height: 72px;
    max-width: 72px;
    flex: none;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }
}

.photo-100 {
  img {
    max-height: 100px;
    max-width: 100px;
    flex: none;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }
}

.photo-200 {
  img {
    max-height: 200px;
    max-width: 200px;
    flex: none;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }
}