﻿table.md-table {
    tbody.md-body {
        > tr.md-row {
            outline: none;
            cursor: pointer;

            &:hover {
                background-color: #EEEEEE;
            }

            &.md-selected {
                background-color: #f5f5f5;
            }

            > td.md-cell {
                md-autocomplete-wrap {
                    box-shadow: none;

                    input {
                        padding: 0;
                        border-bottom: none;

                        &::placeholder {
                            font-size: 12px;
                            color: rgba(0,0,0,.5);
                        }
                    }
                }

                md-select.md-table-select > .md-select-value > span > .md-text {
                    display: inline;
                }

                .md-datepicker-input {
                    border-bottom: none;
                }

                md-chips-wrap {
                    padding: 0;
                    font-size: 13px;
                    box-shadow: none;

                    > md-chip {
                        margin-top: 4px;
                        height: 24px;
                        line-height: 24px;

                        .md-chip-remove {
                            height: 18px;
                            width: 18px;

                            md-icon {
                                height: 18px;
                                width: 18px;
                                top: 12px;
                                left: auto;
                                right: -5px;
                                min-height: 18px;
                                min-width: 18px;
                            }
                        }
                    }

                    > .md-chip-input-container {
                        margin-top: 0;
                        line-height: 24px;
                    }
                }
            }
        }
    }

    td.md-avatar {
        width: 40px;
        padding: 0;

        img {
            -webkit-box-flex: 0;
            -webkit-flex: none;
            flex: none;
            width: 40px;
            height: 40px;
            margin-top: 8px;
            margin-bottom: 8px;
            margin-right: 16px;
            border-radius: 50%;
            box-sizing: content-box;
        }
    }
}

md-toolbar.md-table-toolbar {
    background-color: transparent !important;

    &.alternate {
        color: #1e88e5;
        background-color: #e3f2fd !important;
    }

    &.md-default {
        .md-toolbar-tools {
            .md-button {
                &[disabled] {
                    &.md-icon-button {
                        md-icon {
                            color: rgba(0,0,0,0.33);
                            fill: rgba(0,0,0,0.33);
                        }
                    }
                }

                &.md-primary {
                    color: rgb(33,150,243);
                }
            }
        }
    }
}