﻿h1, h2, h3, h4, h5, h6 {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

html, body, md-content {
  background-color: #fff;
}

.md-tooltip {
    font-size: 14px;
}

md-progress-circular.md-default-theme path, md-progress-circular path {
    stroke: white;
}