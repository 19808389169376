﻿.aims-filter-list md-list-item {
    min-height: 30px;
    max-height: 30px;
    height: 30px;
}

.aims-filter-list md-list-item .md-list-item-inner {
    min-height: 30px;
    max-height: 30px;
    height: 30px;
}

.aims-filter-list .md-subheader .md-subheader-inner {
    padding-top: 16px;
    padding-bottom: 8px;
    color:black;
}

md-sidenav .mCSB_container_wrapper {
    margin-right: 10px;
}

md-sidenav .mCSB_container_wrapper .mCSB_container {
    padding-right: 0px;
}

md-content .mCSB_container_wrapper {
    margin-right: 10px;
}

md-content .mCSB_container_wrapper .mCSB_container {
    padding-right: 0px;
}