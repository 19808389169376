﻿.easy-pie {
  color: rgba(0,0,0,0.87);
  display: inline-block;
  position: relative;
  padding: 0 5px 12px;

  .percent {
    position: absolute;
    font-weight: 300;
    width: 100%;
    line-height: 100%;
    text-align: center;
    left: 0;
    font-size: 26px;
    margin-top: 37px;

    &:after {
      font-size: 20px;
      content: "%";
    }
  }

  .pie-title {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: -3px;
    left: 0;
    font-size: 13px;
  }
}