﻿.aims-chips md-chip {
    position: relative;
    margin-top: 4px;
}

.aims-chips md-chip .md-chip-remove-container {
    position: absolute;
    right: 4px;
    top: 4px;
    margin-right: 0;
    height: 24px;
}

.aims-chips md-chip .md-chip-remove-container button {
    position: relative;
    height: 24px;
    width: 24px;
    line-height: 30px;
    text-align: center;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    border: none;
    box-shadow: none;
    padding: 0;
    margin: 0;
    transition: background 0.15s linear;
    display: block;
}

aims-chips md-chip .md-chip-remove-container button md-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) scale(0.7);
    color: white;
    fill: white;
}

.aims-chips md-chip .md-chip-remove-container button:hover {
    background: rgba(0, 0, 0, 0.4);
}

.aims-chips md-chips-wrap.md-removable md-chip md-chip-template {
    padding-right: 5px;
}