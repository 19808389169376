﻿md-radio-button {
  &.text-nowrap {
    .md-label {
      white-space: nowrap;
    }
  }
}

md-radio-group.ng-empty:focus {
  > md-radio-button:first-child {
    .md-container:before {
      background-color: rgba(0, 0, 0, .12);
      left: -8px;
      top: -8px;
      right: -8px;
      bottom: -8px;
    }
  }
}