﻿md-toolbar {
  &.light-blue {
    background: rgb(3, 155, 229) !important;
    color: rgb(255, 255, 255) !important;
  }

  &.md-sm {
    min-height: 42px;

    .md-toolbar-tools {
      height: 42px;
    }
  }

  .md-toolbar-tools {
    .md-avatar {
      -webkit-box-flex: 0;
      -webkit-flex: none;
      flex: none;
      width: 40px;
      height: 40px;
      margin-top: 8px;
      margin-bottom: 8px;
      margin-right: 16px;
      border-radius: 50%;
      box-sizing: content-box;
    }

    .md-avatar-sm {
      width: 28px;
      height: 28px;

      img {
        width: 28px;
        height: 28px;
      }
    }
  }
}

md-toolbar:not(.md-table-toolbar) {
  color: rgb(255, 255, 255) !important;

  .md-icon-button:not([disabled]) {
    md-icon {
      color: rgb(255, 255, 255) !important;
    }
  }

  .md-icon-button[disabled] {
    md-icon {
      color: rgba(255, 255, 255, 0.24) !important;
    }
  }
}