//
// Utilities classes to simplify
// components constructions
// ------------------------------

$margin-base: 8px;
$margin-sm: 4px;
$margin-lg: 16px;
$margin-xl: 24px;

$padding-xl: 24px;
$padding-lg: 16px;
$padding-base: 8px;
$padding-sm: 4px;

$wd-xxs: 60px;
$wd-xs: 90px;
$wd-sm: 150px;
$wd-sd: 200px; // sd == standard
$wd-md: 240px;
$wd-lg: 280px;
$wd-xl: 320px;
$wd-xxl: 360px;
$wd-wide: 100%;
$wd-auto: auto;
$wd-zero: 0;

@mixin make-bg($bgcolor, $text-color: #fff) {
  background-color: $bgcolor !important;
  color: $text-color !important;
}